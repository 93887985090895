
import * as ipxRuntime$j5ysFE7pq4 from '/usr/src/webshop/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "placeholder-app.com",
    "integritag.com",
    "www.integritag.com",
    "webshop.staging.integritag.com",
    "webshop.testing.integritag.com",
    "connect.ekomi.de",
    "webshop.integritag.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$j5ysFE7pq4, defaults: {} }
}
        